import { Component, OnInit } from '@angular/core';
import { IQuestion } from './models/question.interface';
import { IResponse } from './models/response.interface';

export const LOCAL_STORAGE_KEY = 'qs:health-check';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public mode: string = 'splash';

  public model: IResponse[];

  public questions: IQuestion[];

  public currentQuestion: IQuestion;

  public answer: string = '0';

  public scores: any;

  public async ngOnInit(): Promise<void> {
    this.model = this.loadModel();
    this.questions = await this.loadQuestions();
    this.loadCurrentQuestion();
  }

  public async loadQuestions(): Promise<IQuestion[]> {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();

      xhr.onload = () => {
        const response = xhr.responseText;
        resolve(JSON.parse(response));
      };

      xhr.open('GET', '/assets/questions.json', true);
      xhr.send();
    });
  }

  public loadModel(): IResponse[] {
    const model = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!model) {
      return [];
    }

    return JSON.parse(model);
  }

  public loadCurrentQuestion(): void {
    if (this.model.length >= this.questions.length) {
      this.finish();
    }
    else {
      this.currentQuestion = this.questions[this.model.length];
    }
  }

  public saveModel(): void {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.model));
  }

  public startAssessment(): void {
    this.mode = 'questionnaire';
  }

  public next(): void {
    const response = +this.answer;
    const i = this.model.findIndex((q) => q.controlId === this.currentQuestion.id);
    if (i > -1) {
      this.model[i].response = response;
    }
    else {
      this.model.push({
        controlId: this.currentQuestion.id,
        response,
      });
    }

    this.saveModel();
    this.loadCurrentQuestion();
    this.answer = '0';
  }

  public previous(): void {
    const response = +this.answer;
    // start assesment if no previous question
    if(this.questions[this.model.length-1] === undefined){
      this.model = [];
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      this.loadCurrentQuestion();
      this.mode = 'splash';
    }
    else{
      const id =  this.questions[this.model.length-1].id;
      const i = this.model.findIndex((q) => q.controlId === id);
      if (i < -1) {
        this.model[i].response = response;
      }
      else {
        this.model.pop();
      }
    }
    this.saveModel();
    this.loadCurrentQuestion();
    this.answer = '0';
  }

  public finish(): void {
    this.mode = 'report';

    console.log(this.model);

    const xdrScores = this.getImpact('xdr');
    const vaptScores = this.getImpact('vapt');
    const rmcScores = this.getImpact('rmc');

    this.scores = {
      xdr: xdrScores,
      vapt: vaptScores,
      rmc: rmcScores,
    };
  }

  public getImpact(type): any {
    let total = 0;
    let score = 0;

    this.questions.forEach((q) => {
      const weight = q.scores[type];
      const response = this.model.find((r) => r.controlId === q.id)?.response || 0;
      score += response * weight;
      total += weight;
    });

    let percentage = score / total;
    if (percentage < 0) {
      percentage = 0;
    }
    else if (percentage > 1) {
      percentage = 1;
    }

    return {
      score,
      total,
      percentage,
      items: this.getItems(type, total),
    };
  }

  public getItems(type: string, total: number): any[] {
    let items: any[] = this.questions.filter((q) => q.scores[type] > 0);

    items = items.map((q) => {
      const weight = q.scores[type];
      const response = this.model.find((r) => r.controlId === q.id)?.response || 0;
      const points = response * weight;

      return {
        ...q,
        response,
        score: {
          points,
          percentage: points / total,
        },
      };
    });

    items = items.sort((a, b) => {
      return a.score.percentage - b.score.percentage;
    });

    return items;
  }

  public reset(): void {
    if (confirm('Starting over will clear all of your current responses and return you to the beginning of the survey. Are you sure you want to start over?')) {
      this.model = [];
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      this.loadCurrentQuestion();
      this.mode = 'splash';
    }
  }
}
