import { Component, Input } from '@angular/core';

@Component({
  selector: 'qs-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss'],
})
export class ReportSummaryComponent {
  @Input()
  public name: string;

  @Input()
  public model: any;

  @Input()
  public key: string;

  public showAll: boolean = false;

  public getItemClasses(item: any): string {
    if (item.response < -1) {
      return 'danger';
    }

    if (item.response < 0) {
      return 'danger-warning';
    }

    if (item.response === 0) {
      return 'warning';
    }

    return 'success';
  }

  public getResponseText(response: number): string {
    switch (response) {
      case -1.1:
        return 'Strongly Disagree';
      case -1:
        return 'Disagree';
      case 1:
        return 'Agree';
      case 1.1:
        return 'Strongly Agree';
      case 0:
      default:
        return 'Neutral';
    }
  }

  public getPriorityItems(): any[] {
    return this.model[this.key].items.filter((x) => x.response <= 0);
  }

  public getOtherItems(): any[] {
    return this.model[this.key].items.filter((x) => x.response > 0).reverse();
  }

  public toggleShowAll(): void {
    this.showAll = !this.showAll;
  }

  public getDisplayScore(percentage: number, decimals: number = 0): string {
    const result = (percentage * 100).toFixed(decimals);
    return percentage > 0 ? `+${ result }` : result;
  }
}
