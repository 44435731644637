
<div class="background layer-2"></div>
<div class="background layer-1"></div>

<main>
  <div class="title">
    <a class="logo" href="/index.html">Armor</a>
    <div class="text">Cyber Health Check</div>
  </div>
  <section class="splash" *ngIf="mode === 'splash'">
    <h1>
      Cybersecurity Risk Assessment Tool
      <span class="subtitle">Evaluate high-level risks to map your cybersecurity journey.</span>
    </h1>
    <button mat-raised-button color="primary" (click)="startAssessment()">Start Assessment</button>
    <!--<a href="https://docs.armor.com/" target="_blank" mat-button color="primary">View Documentation</a>-->
    <h3>Notes and Guidance</h3>
    <ul>
      <li>This tool is designed to facilitate high-level discovery as a first step towards formulating your
        cybersecurity strategy. It is not a replacement for an in-depth risk assessment
        &mdash; for information regarding certified risk assessments, read about our
        <a href="https://www.armor.com/services/risk-management-and-compliance" target="_blank">Risk Management &amp; Compliance
          solutions</a>.</li>
      <li>While you may find this tool useful to perform a self-assessment, we recommend an expert walk-through at
        least once to help understand the nuances of the questionnaire. You can schedule a curated health check by
        filling out a <a href="https://www.armor.com/forms/cyber-health-check" target="_blank">request form</a>.</li>
    </ul>

  </section>
  <section class="questionnaire" *ngIf="mode === 'questionnaire'">
    <div class="question">
      <div class="progress">
        <div class="info">
          <div class="status">Question {{ model?.length + 1 }} of {{ questions?.length }}</div>
          <div class="category">{{ currentQuestion?.category }}</div>
        </div>
        <mat-progress-bar mode="determinate" value="{{ (model?.length / questions?.length) * 100 }}"></mat-progress-bar>
      </div>

      <h1 class="text">{{ currentQuestion?.control }}</h1>
      <div class="description">
        <p>{{ currentQuestion?.description }}</p>
      </div>

      <mat-radio-group [(ngModel)]="answer">
        <div class="answer">
          <div class="response">
            <label class="label">Strongly Disagree</label>
            <mat-radio-button name="answer" value="-2"></mat-radio-button>
          </div>
          <div class="response">
            <label class="label">Disagree</label>
            <mat-radio-button name="answer" value="-1.1"></mat-radio-button>
          </div>
          <div class="response">
            <label class="label">Neutral</label>
            <mat-radio-button name="answer" value="0"></mat-radio-button>
          </div>
          <div class="response">
            <label class="label">Agree</label>
            <mat-radio-button name="answer" value="1"></mat-radio-button>
          </div>
          <div class="response">
            <label class="label">Strongly Agree</label>
            <mat-radio-button name="answer" value="1.1"></mat-radio-button>
          </div>
        </div>
      </mat-radio-group>

      <div class="controls">
        <div class="previous">
          <button mat-raised-button color="secondary" (click)="previous()">Previous</button>
        </div>
        <div class="next">
          <button mat-raised-button color="primary" (click)="next()">Next</button>
        </div>
      </div>

    </div>
  </section>

  <section *ngIf="mode === 'report'">
    <h5 class="cta">If you&apos;d like to discuss your results, please <a href="https://www.armor.com/company/contact" target="_blank">contacts us</a>.</h5>
    <div class="scores-wrapper">
      <div class="scores">
        <qs-score
          [score]="scores.xdr.percentage"
          [label]="'Protection, Detection & Response'">
        </qs-score>
        <qs-score
          [score]="scores.vapt.percentage"
          [label]="'Vulnerability Management'">
        </qs-score>
        <qs-score
          [score]="scores.rmc.percentage"
          [label]="'Risk Management & Compliance'">
        </qs-score>
      </div>

      <ul class="legend">
        <li class="danger"><span class="tile"></span> 0-49</li>
        <li class="warning"><span class="tile"></span> 50-89</li>
        <li class="success"><span class="tile"></span> 90-100</li>
      </ul>
    </div>

    <qs-report-summary
      [model]="scores"
      [name]="'Protection, Detection & Response'"
      [key]="'xdr'">
    </qs-report-summary>

    <mat-divider></mat-divider>

    <qs-report-summary
      [model]="scores"
      [name]="'Vulnerability Management'"
      [key]="'vapt'">
    </qs-report-summary>

    <mat-divider></mat-divider>

    <qs-report-summary
      [model]="scores"
      [name]="'Risk Management & Compliance'"
      [key]="'rmc'">
    </qs-report-summary>

    <mat-divider></mat-divider>

    <div class="footer">
      <button mat-raised-button color="warn" (click)="reset()">Start Over</button>
    </div>
  </section>
</main>
