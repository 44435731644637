import { Component, Input } from '@angular/core';

@Component({
  selector: 'qs-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent {
  @Input()
  public score: number;

  @Input()
  public label: string;

  public getScoreClass(): string {
    if (this.score >= 0.9) {
      return 'success';
    }

    if (this.score >= 0.6) {
      return 'warning';
    }

    return 'error';
  }

  public getDisplayScore(percentage: number): string {
    return Math.round(percentage * 100).toString(10);
  }
}
