<div class="summary">
  <qs-score
    [score]="model[key].percentage"
    [label]="name">
  </qs-score>
</div>
<div class="guidance">
  <ng-template #itemTitle let-item="item">
    <h4><span class="tile"></span><span class="text">{{ item.control }}</span></h4>
  </ng-template>
  <ng-template #itemSummary let-item="item">
    <div class="expanded-content">
      <label>Response</label>
      <p class="response-type">{{ getResponseText(item.response) }} ({{ getDisplayScore(item.score.percentage, 1) }}%)</p>
      <label>Control Description</label>
      <p>{{ item.description }}</p>
      <div class="remediation" *ngIf="item.guidance[key]">
        <label>Remediation Guidance</label>
        <p>{{ item.guidance[key] }}</p>
      </div>
    </div>
  </ng-template>
  <div class="screen-only">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of getPriorityItems()" class="{{ getItemClasses(item) }}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-template
              [ngTemplateOutlet]="itemTitle"
              [ngTemplateOutletContext]="{item:item}">
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent
          [ngTemplateOutlet]="itemSummary"
          [ngTemplateOutletContext]="{item:item}">
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <button class="show-more screen-only" (click)="toggleShowAll()">
    <span *ngIf="showAll">Show Less</span>
    <span *ngIf="!showAll">Show More</span>
  </button>

  <div class="more screen-only" *ngIf="showAll">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of getOtherItems()" class="{{ getItemClasses(item) }}">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ng-template
              [ngTemplateOutlet]="itemTitle"
              [ngTemplateOutletContext]="{item:item}">
            </ng-template>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent
          [ngTemplateOutlet]="itemSummary"
          [ngTemplateOutletContext]="{item:item}">
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ul class="full-list print-only">
    <li *ngFor="let item of model[key].items" class="{{ getItemClasses(item) }}">
      <ng-template
        [ngTemplateOutlet]="itemTitle"
        [ngTemplateOutletContext]="{item:item}">
      </ng-template>
      <ng-template
        [ngTemplateOutlet]="itemSummary"
        [ngTemplateOutletContext]="{item:item}">
      </ng-template>
    </li>
  </ul>
</div>
